import React, { Fragment } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

import chainArbitrum from 'blockscope/assets/chains/arbitrum-logo.svg';
import chainBase from 'blockscope/assets/chains/base-logo.svg';
import chainBinanace from 'blockscope/assets/chains/binance-logo.svg';
import chainBlast from 'blockscope/assets/chains/blast-logo.svg';
import chainCelo from 'blockscope/assets/chains/celo-logo.svg';
import chainEthereum from 'blockscope/assets/chains/ethereum-eth-logo.svg';
import chainFantom from 'blockscope/assets/chains/fantom-ftm-logo.svg';
import chainOptimism from 'blockscope/assets/chains/optimism-ethereum-logo.svg';
import chainPolygon from 'blockscope/assets/chains/polygon-matic-logo.svg';
import chainTron from 'blockscope/assets/chains/tron-logo.svg';

import Section from 'blockscope/components/common/Section';

const Chains = () => {
  const chains = [
    chainEthereum,
    chainBase,
    chainPolygon,
    chainOptimism,
    chainArbitrum,
    chainBinanace,
    chainTron,
  ];

  return (
    <Fragment>
      <Section bg='light' className='py-3 shadow-sm'>
        <Container>
          <Row className='flex-center pt-5 pb-3'>
            <h3 className='text-center'>Integrated on Popular Chains!</h3>
          </Row>

          <Row className='flex-center mb-5'>
            {chains.map((chain, index) => {
              return (
                <Fragment key={index}>
                  <Col xs={3} sm='auto' className='my-1 my-sm-3 px-card'>
                    <Card>
                      <Card.Body>
                        <Image
                          className='landing-cta-img'
                          src={chain}
                          height={50}
                          width={50}
                          alt='Partner'
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Fragment>
              );
            })}
          </Row>
        </Container>
      </Section>
    </Fragment>
  );
};

export default Chains;

