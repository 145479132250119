import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';

import ContactUsPage from 'blockscope/components/pages/ContactUsPage';

const Support = () => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className='d-grid gap-2'>
        <Button size='sm' className='btn-light me-3' onClick={() => toggle()}>
          Support
        </Button>
      </div>

      <Modal show={showModal} onHide={() => toggle()} size='l'>
        <Card>
          <Card.Body className='p-4 p-sm-5'>
            <ContactUsPage />
          </Card.Body>
        </Card>
      </Modal>
    </>
  );
};

export default Support;

