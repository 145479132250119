export const ORG_SERVICES = {
  // *************************************************
  // BLOCKSCOPE INTERNAL SERVICES
  // *************************************************
  BLOCKSCOPE_INTERNAL_CONTROLS: 'blockscope-internal-controls',

  // *************************************************
  // META Tools
  // *************************************************
  INVESTIGATOR: 'investigator',
  USERS: 'users',

  // *************************************************
  // Data Capture Services
  // *************************************************
  WATCHTOWER: 'watchtower',
  PIPELINES: 'pipelines',

  // *************************************************
  // Chain Exploration Services
  // *************************************************
  TRANSACTION_DECODER: 'transaction-decoder',
  TRANSACTION_SIMULATOR: 'transaction-simulator',
  BLOCK_BREAKDOWN: 'block-breakdown',
  CONTRACT_USAGE: 'contract-usage',
  ADDRESS_ACTIVITY: 'address-activity',
  CONTRACT_BREAKDOWN: 'contract-breakdown',
  CONTRACT_BREAKDOWN_AI: 'contract-breakdown-ai',
  ADVANCED_SEARCH: 'advanced-search',

  // *************************************************
  // Tracking and Investigation Services
  // *************************************************
  TRACER_TOKENFLOW: 'tracer-tokenflow',
  TRACER_INVESTIGATION: 'tracer-investigation',
  TRACER_TRANSACTION_TOKENFLOW: 'tracer-transaction-tokenflow',
  TRACER_ANALYTICS: 'tracer-analytics',
  MEMO: 'memo',
  ENTITY_INTERACTION: 'entity-interaction',
  COHORT_ANALYSIS: 'cohort-analysis',

  // *************************************************
  // DEV Tools
  // *************************************************
  EVM_EVENT_DIRECTORY: 'evm-event-directory',
  EVM_FUNCTION_DIRECTORY: 'evm-function-directory',

  // *************************************************
  // WEB3 Data Services
  // *************************************************
  WEB3_ENTITIES: 'web3-entities',
  WEB3_HACKS: 'web3-hacks',
  WEB3_LABELS: 'web3-labels',

  // *************************************************
  // Oracle Services
  // *************************************************
  ORACLE: 'oracle',
  ORACLE_MONITORED_ADDRESSES: 'oracle-monitored-addresses',
  ORACLE_RULES: 'oracle-rules',

  // *************************************************
  // Developer API Services
  // *************************************************
  RISK_API: 'risk-api',
  LABEL_API: 'label-api',
  DECODER_API: 'decoder-api',
  COMPLIANCE_LISTS: 'compliance-lists',
};

export const ORG_SERVICES_OPTIONS = Object.values(ORG_SERVICES).map(
  (service) => ({ value: service, label: service })
);
