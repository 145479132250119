import React from 'react';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';

import Support from '../vertical/Support';
import PropTypes from 'prop-types';

import NotificationDropdown from 'blockscope/components/navbar/top/NotificationDropdown';
import OrgSelectionDropdown from 'blockscope/components/navbar/top/OrgSelectionDropdown';
import ProfileDropdown from 'blockscope/components/navbar/top/ProfileDropdown';

const TopNavRightSideNavItem = ({ orgReducer: { orgDetails } }) => {
  return (
    <Nav
      navbar
      className='navbar-nav-icons ms-auto flex-row align-items-center'
      as='ul'
    >
      <Support />
      <NotificationDropdown />

      {orgDetails?.org ? <OrgSelectionDropdown className='outline' /> : null}

      <ProfileDropdown />
    </Nav>
  );
};

TopNavRightSideNavItem.propTypes = {
  orgReducer: PropTypes.shape({
    orgDetails: PropTypes.shape({
      org: PropTypes.object,
    }),
  }),
};

const mapStateToProps = (state) => ({
  orgReducer: state.orgReducer,
});

export default connect(mapStateToProps, {})(TopNavRightSideNavItem);

