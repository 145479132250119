import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import Flex from 'blockscope/components/common/Flex';
import Section from 'blockscope/components/common/Section';

import { productData } from 'blockscope/components/navbar/top/dropdownOptions/dropdownData/products';
import SectionHeader from 'blockscope/components/pages/landing/components/subComponents/SectionHeader';

const ProductItem = ({ product, enabled }) => {
  return (
    <Col lg={6} md={6}>
      <Flex
        alignItems='center'
        className='h-100 px-3 bg-light rounded-3 border position-relative'
      >
        <img src={product.img} alt='' width='39' />
        <div className='ms-3 my-x1'>
          <h5 className='fs-0 fw-semi-bold mb-2'>
            {enabled ? (
              <Link
                to={product.link}
                className='text-900 hover-primary stretched-link'
              >
                {product.title}
              </Link>
            ) : (
              <Link to={'#'} className='text-900 hover-primary stretched-link'>
                {product.title}
              </Link>
            )}
          </h5>
          <h6 className='mb-0 text-600'>{product.description}</h6>

          {/* {product.toolkits.map((toolkit, index) => (
            <Badge bg='' className='badge-soft-dark me-2 mt-2' key={index}>
              {toolkit}
            </Badge>
          ))} */}
        </div>
      </Flex>
    </Col>
  );
};

ProductItem.propTypes = {
  enabled: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    toolkits: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
const Tools = () => {
  return (
    <Section
      bg='white'
      id='servicesSection'
      className={'d-flex justify-content-center align-items-center'}
    >
      <SectionHeader title='Products & Tools' />
      <br></br>

      <Row>
        <Col xxl={8} xl={8} lg={10} md={12} className='mx-auto'>
          <Row className='g-3'>
            {productData.map((p, index) => (
              <ProductItem key={index} product={p} enabled={true} />
            ))}
          </Row>
        </Col>
      </Row>
    </Section>
  );
};

export default Tools;

