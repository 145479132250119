import React from 'react';

import classNames from 'classnames';

/**
 * Flex component to create a flexible box layout.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.justifyContent] - Defines how the browser distributes space between and around content items along the main-axis of their container.
 * @param {string} [props.alignItems] - Defines the default behavior for how flex items are laid out along the cross axis on the current line.
 * @param {string} [props.alignContent] - Defines the distribution of space between and around content items along a flex container's cross-axis.
 * @param {boolean} [props.inline] - If true, sets the display to inline-flex.
 * @param {string} [props.wrap] - Defines whether the flex container is single-line or multi-line, and the direction of the cross-axis, which determines the direction new lines are stacked in.
 * @param {string} [props.className] - Additional class names to apply to the component.
 * @param {string|React.ElementType} [props.tag='div'] - The HTML tag or React component to use for the outer element.
 * @param {React.ReactNode} [props.children] - The content to be rendered inside the component.
 * @param {string} [props.breakpoint] - The breakpoint at which the flex properties should apply.
 * @param {string} [props.direction] - Defines the direction of the main-axis (row or column).
 * @param {Object} [props.rest] - Additional properties to be spread onto the outer element.
 *
 * @returns {JSX.Element} The rendered Flex component.
 */
const Flex = ({
  justifyContent,
  alignItems,
  alignContent,
  inline,
  wrap,
  className,
  tag: Tag = 'div',
  children,
  breakpoint,
  direction,
  ...rest
}) => {
  return (
    <Tag
      className={classNames(
        {
          [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
          [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Flex;
