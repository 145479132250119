import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { version } from 'config';
import PropTypes from 'prop-types';

import { companyFooterList } from 'blockscope/components/pages/landing/components/static/footerConsts';
import { bgWhiteIcons } from 'blockscope/components/pages/landing/components/static/socialIcons';

import soc2_t1_logo from 'blockscope/assets/img/landing/soc2_t1.png';

import Section from 'blockscope/components/common/Section';
import IconGroup from 'blockscope/components/common/icon/IconGroup';

import MailingListForm from 'components/pages/landing/MailingListForm';

const FooterTitle = ({ children }) => (
  <h5 className='text-uppercase text-white opacity-85 mb-3'>{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className='list-unstyled'>
    {list.map(({ title, to, external }, index) => (
      <li className='mb-1' key={index}>
        {external ? (
          <a className='text-600' href={to}>
            {title}
          </a>
        ) : (
          <Link className='text-600' to={to}>
            {title}
          </Link>
        )}
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = () => {
  // const { data: blogsList } = useGetAllBlogs_Query();

  const blogsList = [
    {
      title: 'Stablecoin in 2024: Beyond Basics',
      createdAt: 'Dec 3, 2024',
      link: 'https://medium.com/@blockscope.co/stablecoins-in-2024-beyond-basics-253da1cf531c',
    },
    {
      title: 'Demystifiying Defi: Euler Finance',
      createdAt: 'Nov 14, 2024',
      link: 'https://medium.com/@blockscope.co/demystifying-defi-euler-finance-4db40087e07f',
    },
    {
      title: `MiCA: The EU's Crypto Regulatrion`,
      createdAt: 'Nov 2, 2024',
      link: 'https://medium.com/@blockscope.co/mica-the-eus-crypto-regulation-2ad056032467',
    },
  ];

  return (
    <ul className='list-unstyled'>
      {blogsList?.slice(0, 3).map((blog, index) => (
        <li key={index}>
          <h5 className='fs-0 mb-0'>
            <Link className='text-600' to={blog.link}>
              {blog.title}
            </Link>
          </h5>
          <p className='text-600 opacity-50'>
            {new Date(blog.createdAt).toLocaleDateString()}
          </p>
        </li>
      ))}
    </ul>
  );
};

FooterBlogList.propTypes = {};

const FooterStandard = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <Section bg='dark' className='pt-8 pb-4 light'>
        <div
          className='position-absolute btn-back-to-top cursor-pointer bg-dark'
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            icon='chevron-up'
            transform='rotate-45'
            className='text-600'
          />
        </div>
        <Row>
          <Col lg={3} className='me-8'>
            <FooterTitle>Our Mission</FooterTitle>
            <p className='text-600'>
              Build tools to capture, decode, analyze and understand web3 data.
            </p>
            <IconGroup className='mt-4' icons={bgWhiteIcons} />
            <Image
              src={soc2_t1_logo}
              width={125}
              height={125}
              className='mx-1 mt-3'
              alt='logo'
            />
          </Col>
          {/* className='ps-lg-6 ps-xl-10' */}
          <Col className='pl-4'>
            <Row className='mt-5 mt-lg-0'>
              <Col xs={6} md={3}>
                <FooterTitle>Company</FooterTitle>
                <FooterList list={companyFooterList} />
              </Col>

              <Col className='mt-5 mt-md-0'>
                <FooterTitle>Blog</FooterTitle>
                <FooterBlogList />
              </Col>

              <Col xs={6} md={4}>
                {/* <FooterTitle>Product</FooterTitle>
                <FooterList list={productFooterList} /> */}
                <FooterTitle>Sign up for updates!</FooterTitle>
                <MailingListForm />
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>

      <section className=' bg-dark py-0 text-center fs--1 light'>
        <hr className='my-0 border-600 opacity-25' />
        <div className='container py-3'>
          <Row className='justify-content-between'>
            <Col xs={12} sm='auto'>
              <p className='mb-0 text-600'>
                Web3 Data Platform
                <span className='d-none d-sm-inline-block'>| </span>
                <br className='d-sm-none' /> {new Date().getFullYear()} &copy;{' '}
                <a href='https://www.blockscope.co/'>Blockscope</a>
              </p>
            </Col>
            <Col xs={12} sm='auto'>
              <p className='mb-0 text-600'>v{version}</p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FooterStandard;

