import React from 'react';
import { Container } from 'react-bootstrap';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import FTLogo from 'blockscope/assets/img/investors/FTLogo.png';
import YCLogo from 'blockscope/assets/img/investors/YC.svg';
import gCCLogo from 'blockscope/assets/img/investors/gCCLogo.svg';

const Backers = () => {
  return (
    <section className='pt-6 pb-6 bg-light'>
      <Container>
        <div className='my-5 text-center'>
          <h3 className='mb-4 text-500'>Backed By</h3>
          <div>
            <img
              src={FTLogo}
              width={'250'}
              alt='Bootstrap'
              className='ms-4 me-2'
            />
            <img
              src={gCCLogo}
              width={'160'}
              style={{ marginBottom: '20px' }}
              alt='Bootstrap'
              className='mx-4'
            />
            <img src={YCLogo} width={'250'} alt='Bootstrap' className='mx-4' />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Backers;

