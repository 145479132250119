export const TOAST_HANDLER = {
  SUCCESS: 'successToast',
  ERROR: 'errorToast',
  WARNING: 'warningToast',
  AXIOS_SUCCESS: 'axiosSuccessToast',
  AXIOS_ERROR: 'axiosErrorToast',
  AXIOS_PLACEHOLDER: 'somethingWentWrongToast',
};

export const GENERAL_TOAST_ID = {
  ERROR: {
    COPY_TO_CLIPBOARD: 'clipboardCopyErrorToast',
    ACCESS_REVOKED: 'accessRevokedErrorToast',
  },
  SUCCESS: {
    COPY_TO_CLIPBOARD: 'clipboardCopySuccessToast',
    CONTACT_BLOCKSCOPE: 'contactBlockscopeSuccessToast',
  },
};

export const BLOCK_BREAKDOWN_TOAST_ID = {
  COPY: 'clipboardCopyToast',
  INVALID_ADDRESS: 'invalidAddressErrorToast',
  GET_BLOCK_DATA: 'getBlockDataErrorToast',
};

export const WALLET_ADDRESS_DISPLAY_TOAST_ID = {
  COPY: 'addressCopiedToast',
};

export const FN_HASH_DISPLAY_TOAST_ID = {
  COPY: 'functionHashCopiedToast',
};
export const TXN_HASH_DISPLAY_TOAST_ID = {
  COPY: 'hashCopiedToast',
};

export const PIPELINE_TOAST_ID = {
  SUCCESS: {
    GET_DELIVERY_DOCS: 'getPipelineDeliveryDocsMutationSuccessToast',
    ACTIVATE: 'activatePipelineSuccessToast',
    DEACTIVATE: 'deactivatePipelineSuccessToast',
    DELETE: 'deletePipelineSuccessToast',
    CREATE: 'createPipelineSuccessToast',
  },
  ERROR: {
    GET_ORG_PIPELINES: 'getOrgPipelinesErrorToast',
    GET_PIPELINE: 'getPipelineErrorToast',
    GET_DELIVERY_DOCS: 'getPipelineDeliveryDocsErrorToast',
    ACTIVATE: 'activatePipelineErrorToast',
    DEACTIVATE: 'deactivatePipelineErrorToast',
    DELETE: 'deletePipelineErrorToast',
    CREATE: 'createPipelineErrorToast',
    MISSING_DELIVERY_TARGET: 'missingDeliveryTargetErrorToast',
    NAME_TOO_SHORT: 'pipelineNameTooShortErrorToast',
    ACTIVE_TARGET_DELETE: 'activeTargetDeleteErrorToast',
    INVALID_DELETE: 'invalidDeleteErrorToast',
    UNDEFINED_FILTERS: 'undefinedPipelineFilterErrorToast',
    GET_MANAGED_FILTER_VALUES: 'getManagedFilterValuesErrorToast',
  },
};

export const INVESTIGATOR_TOAST_ID = {
  SUCCESS: {
    CREATE_INVESTIGATION: 'createInvestigationMutationSuccessToast',
    GET_INVESTIGATIONS: 'getOrgInvestigationsSuccessToast',
    GET_INVESTIGATION: 'getSingleInvestigationSuccessToast',
    DELETE: 'deleteInvestigationMutationSuccessToast',
    CREATE_INVESTIGATION_ITEM: 'createInvestigationItemMutationSuccessToast',
    DELETE_INVESTIGATION_ITEM: 'deleteInvestigationItemMutationSuccessToast',
    UPDATE_INVESTIGATION_ITEM: 'updateInvestigationItemMutationSuccessToast',
    UPDATE_INVESTIGATION: 'updateInvestigationMutationSuccessToast',
    CREATE_COMMENT: 'createCommentMutationSuccessToast',
    UPDATE_COMMENT: 'updateCommentMutationSuccessToast',
    DELETE_COMMENT: 'deleteCommentMutationSuccessToast',
    ADD_COMPONENT: 'addComponentMutationSuccessToast',
    REMOVE_COMPONENT: 'removeComponentMutationSuccessToast',
  },
  ERROR: {
    CREATE_INVESTIGATION: 'createInvestigationMutationErrorToast',
    GET_INVESTIGATIONS: 'getOrgInvestigationsErrorToast',
    GET_INVESTIGATION: 'getSingleInvestigationErrorToast',
    DELETE: 'deleteInvestigationMutationErrorToast',
    CREATE_INVESTIGATION_ITEM: 'createInvestigationItemMutationErrorToast',
    DELETE_INVESTIGATION_ITEM: 'deleteInvestigationItemMutationErrorToast',
    UPDATE_INVESTIGATION_ITEM: 'updateInvestigationItemMutationErrorToast',
    UPDATE_INVESTIGATION: 'updateInvestigationMutationErrorToast',
    CREATE_COMMENT: 'createCommentMutationErrorToast',
    UPDATE_COMMENT: 'updateCommentMutationErrorToast',
    DELETE_COMMENT: 'deleteCommentMutationErrorToast',
    ADD_COMPONENT: 'addCommentMutationErrorToast',
    REMOVE_COMPONENT: 'removeCommentMutationErrorToast',
  },
};

export const WATCHTOWER_TOAST_ID = {
  SUCCESS: {
    GET_RECORDS: 'getRecordsMutationSuccessToast',
    ACTIVATE: 'activateWatchtowerSuccessToast',
    DEACTIVATE: 'deactivateWatchtowerSuccessToast',
    PUBLICIZE: 'pubicizeWatchtowerSuccessToast',
    DEPUBLICIZE: 'depubicizeWatchtowerSuccessToast',
    DELETE: 'deleteWatchtowerSuccessToast',
    CREATE: 'createWatchtowerSuccessToast',
    UPDATE: 'updateWatchtowerSuccessToast',
  },
  ERROR: {
    GET_ORG_WATCHTOWERS: 'getOrgWatchtowersErrorToast',
    GET_WATCHTOWER: 'getWatchtowerErrorToast',
    GET_RECORDS: 'getRecordsErrorToast',
    ACTIVATE: 'activateWatchtowerErrorToast',
    DEACTIVATE: 'deactivateWatchtowerErrorToast',
    PUBLICIZE: 'pubicizeWatchtowerErrorToast',
    DEPUBLICIZE: 'depubicizeWatchtowerErrorToast',
    DELETE: 'deleteWatchtowerErrorToast',
    CREATE: 'createWatchtowerErrorToast',
    NAME_TOO_SHORT: 'watchtowerNameTooShortErrorToast',
    UNDEFINED_FILTERS: 'undefinedWatchtowerFilterErrorToast',
    UPDATE: 'updateWatchtowerErrorToast',
  },
};

export const RISK_RULE_TOAST_ID = {
  SUCCESS: {
    START: 'activateRiskRuleSuccessToast',
    STOP: 'deactivateRiskRuleSuccessToast',
    DELETE: 'deleteRiskRuleSuccessToast',
    CREATE: 'createRiskRuleSuccessToast',
  },
  ERROR: {
    GET_ORG_RULES: 'getOrgRiskRulesErrorToast',
    GET_RULE: 'getRiskRuleErrorToast',

    ACTIVATE: 'activateRiskRuleErrorToast',
    DEACTIVATE: 'deactivateRiskRuleErrorToast',
    DELETE: 'deleteRiskRuleErrorToast',
    CREATE: 'createRiskRuleErrorToast',
  },
};

export const AUTH_ACTION_TOAST_ID = {
  SUCCESS: {
    REGISTER: 'registerUserSuccessToast',
    CONFIRM_EMAIL: 'confirmEmailSuccessToast',
    RESEND_CONFIRM_EMAIL: 'resendConfirmationEmailSuccessToast',
    SEND_FORGOT_PASSWORD: 'sendForgotPasswordEmailSuccessToast',
    PASSWORD_RESET: 'passwordResetSubmissionSuccessToast',
    CHANGE_PASSWORD: 'changePasswordSuccessToast',
    UPDATE_ADDRESS_VIEW: 'updateAddressViewSuccessToast',
  },
  ERROR: {
    REGISTER: 'registerUserErrorToast',
    LOGIN: 'loginErrorToast',
    CONFIRM_EMAIL: 'confirmEmailErrorToast',
    RESEND_CONFIRM_EMAIL: 'resendConfirmationEmailErrorToast',
    SEND_FORGOT_PASSWORD: 'sendForgotPasswordErrorToast',
    PASSWORD_RESET: 'passwordResetErrorToast',
    CHANGE_PASSWORD: 'changePasswordErrorToast',
    UPDATE_ADDRESS_VIEW: 'updateAddressViewErrorToast',
  },
};

export const DELIVERY_SETTINGS_TOAST_ID = {
  SUCCESS: {
    DELETE: 'deleteDeliveryTargetSuccessToast',
  },
  ERROR: {
    CREATE: 'createDeliveryTargetErrorToast',
    DELETE: 'deleteDeliveryTargetErrorToast',
    FETCH_SINGLE: 'fetchSingleDeliveryTargetErrorToast',
    FETCH_MULTIPLE: 'fetchMultipleDeliveryTargetsErrorToast',
  },
};

export const ORG_ACTIONS_TOAST_ID = {
  SUCCESS: {
    CREATE: 'createOrgSuccessToast',
    CANCEL_INVITE: 'cancelInviteSuccessToast',
    RESEND_INVITE: 'resendInviteSuccessToast',
    INVITE_USER: 'inviteUserSuccessToast',
    REMOVE_USER: 'removeUserSuccessToast',
    MANAGE_USER_ROLES: 'manageUserRolesSuccessToast',
  },
  ERROR: {
    CREATE: 'createOrgErrorToast',
    CANCEL_INVITE: 'cancelInviteErrorToast',
    RESEND_INVITE: 'resendInviteErrorToast',
    INVITE_USER: 'inviteUserErrorToast',
    REMOVE_USER: 'removeUserErrorToast',
    MANAGE_USER_ROLES: 'manageUserRolesErrorToast',
  },
};

export const OTHER_ACTIONS_TOAST_ID = {
  SUCCESS: {
    CONTACT_US: 'contactUsSuccessToast',
  },
  ERROR: {
    CONTACT_US: 'contactUsErrorToast',
  },
};

export const CONFIRM_EMAIL_ALERT_TOAST_ID = {
  ERROR: 'ConfirmEmailAlertErrorToast',
};

export const CONTRACT_USAGE_TOAST_ID = {
  ERROR: 'contractusageErrorToast',
};

export const ABI_GUESSER_TOAST_ID = {
  ERROR: 'abiGuesserErrorToast',
};

export const EVM_DIRECTORY_TOAST_ID = {
  ERROR: {
    EVENTS: 'directoryEventsErrorToast',
    FUNCTIONS: 'directoryFunctionsErrorToast',
  },
};
export const TOKENFLOW_TOAST_ID = {
  ERROR: 'tokenflowErrorToast',
};

export const TRACER_TOAST_ID = {
  ERROR: 'tracerErrorToast',
};

export const COHORT_ANALYSIS_TOAST_ID = {
  ERROR: 'cohortAnalysisErrorToast',
};

export const DECODER_TOAST_ID = {
  ERROR: {
    TXN_DECODER: 'transactionDecoderErrorToast',
    EVENT_STREAM: 'eventStreamErrorToast',
    TXN_STREAM: 'transactionStreamErrorToast',
    TRACE_STREAM: 'traceStreamErrorToast',
    GET_BLOCK_DATA: 'getBlockDataErrorToast',
  },
};

export const SIMULATOR_TOAST_ID = {
  ERROR: 'txnSimulatorErrorToast',
};

export const PROFILER_TOAST_ID = {
  LOAD_ERROR: 'profilerLoadErrorToast',
  BALANCE_LOAD_ERROR: 'balanceLoadErrorToast',
};

export const BLOCK_STREAM_TOAST_ID = {
  ERROR: {
    GET_BLOCK_DATA: 'getBlockDataErrorToast',
  },
};

export const CONTRACT_DIRECTORY_TOAST_ID = {
  ERROR: 'searchContractsErrorToast',
};

export const CONTRACT_BREAKDOWN_TOAST_ID = {
  ERROR: {
    SEARCH: 'searchContractsErrorToast',
    SIGNATURE_EXPLAINER: 'signatureExplainerErrorToast',
  },
};

export const LOCK_SCREEN_TOAST_ID = {
  SUCCESS: 'lockScreenSuccessToast',
};

export const PROFILE_DROPDOWN_TOAST_ID = {
  SUCCESS: 'LogOutSuccessToast',
};

export const REGISTRATION_TOAST_ID = {
  ERROR: {
    PASSWORD_MISMATCH: 'passwordMismatchErrorToast',
  },
};

export const MAIN_SEARCH_TOAST_ID = {
  ERROR: {
    GET_LATEST_DATA: 'getLatestDataErrorToast',
  },
};
export const SEARCH_CONTRACT_TOAST_ID = {
  ERROR: {
    SEARCH: 'getSearchContractErrorToast',
  },
};

export const DASHBOARD_TOAST_ID = {
  SERVICE_DISABLED: 'serviceDisabledToast',
  ONBOARDING_CHECK: 'onboardingCheckToast',
};

export const USER_SETTINGS_TOAST_ID = {
  ERROR: {
    PASSWORD_MISMATCH: 'settingsPasswordMismatchErrorToast',
  },
};

export const ORG_SETTING_TOAST_ID = {
  ERROR: { GET_USAGE_REPORTS: 'getUsageReportsErrorToast' },
};
export const TODOS_TOAST_ID = {
  ERROR: {
    GET_TODOS: 'getTodosErrorToast',
    COMPLETED: 'CompleteTodoErrorToast',
  },
  SUCCESS: { COMPLETED: 'CompletedTodoSuccessToast' },
};

export const STRIPE_TOAST_ID = {
  ERROR: {
    INVOICE_FETCH_FAILED: 'invoiceFetchFailedToast',
  },
  WARN: {
    PAYMENT_CANCELLED: 'paymentCancelledToast',
    ONBOARDING_INCOMPLETE: 'onboardingIncompleteToast',
    ENTERPRISE_PURCHASE_ATTEMPT: 'enterprisePurchaseAttemptToast',
  },
  SUCCESS: {
    PAYMENT_SUCCESS: 'paymentSuccessToast',
  },
};
export const NOTIFICATION_TOAST_ID = {
  ERROR: {
    GET_NOTIFICATIONS: 'getNotificationsErrorToast',
    MARK_AS_READ: 'markAsReadErrorToast',
    MARK_ALL_AS_READ: 'markAllAsReadErrorToast',
  },
};

export const APIKEY_TOAST_ID = {
  ERROR: {
    GET_APIKEYS: 'getApiKeysErrorToast',
    GENERATE_APIKEY: 'generateApiKeyErrorToast',
  },
  SUCCESS: {
    GENERATE_APIKEY: 'generateApiKeySuccessToast',
  },
};

export const WEB3_ENTITIES_TOAST_ID = {
  ERROR: {
    GET_ALL: 'getAllWeb3EntitiesErrorToast',
    CREATE: 'createWeb3EntitiesErrorToast',
    UPDATE: 'updateWeb3EntitiesErrorToast',
  },
  SUCCESS: {
    CREATE: 'createWeb3EntitiesSuccessToast',
    UPDATE: 'updateWeb3EntitiesSuccessToast',
  },
};
export const BLOGS_TOAST_ID = {
  ERROR: {
    GET_ALL: 'getAllBlogsErrorToast',
    CREATE: 'createBlogsErrorToast',
    UPDATE: 'updateBlogsErrorToast',
  },
  SUCCESS: {
    CREATE: 'createBlogsSuccessToast',
    UPDATE: 'updateBlogsSuccessToast',
  },
};

export const LABELS_TOAST_ID = {
  ERROR: {
    FETCH: 'FetchLabelsErrorToast',
    GET_ALL: 'getAllLabelsErrorToast',
    CREATE: 'createLabelErrorToast',
    UPDATE: 'updateLabelErrorToast',
  },
  WARN: {
    FETCH: 'FetchLabelsWarnToast',
  },
  SUCCESS: {
    CREATE: 'createLabelSuccessToast',
    UPDATE: 'updateLabelSuccessToast',
  },
};

export const ORG_MANAGEMENT_TOAST_ID = {
  ERROR: {
    GET_ALL: 'getAllOrgsErrorToast',
    SUSPEND: 'suspendOrgErrorToast',
    RELEASE_SERVICES: 'releaseServicesErrorToast',
    ADD_USER: 'addUserErrorToast',
    MANAGE_USER_ROLES: 'manageUserRolesErrorToast',
    MANAGE_SERVICES: 'manageServicesErrorToast',
    DELETE_ORG: 'deleteOrgErrorToast',
    ACTIVATE_SUBSCRIPTION: 'activateSubscriptionErrorToast',
    GENERATE_STRIPE_SESSION: 'generateStripeSessionErrorToast',
  },
  SUCCESS: {
    SUSPEND: 'suspendOrgSuccessToast',
    RELEASE_SERVICES: 'releaseServicesSuccessToast',
    ADD_USER: 'addUserSuccessToast',
    MANAGE_USER_ROLES: 'manageUserRolesSuccessToast',
    MANAGE_SERVICES: 'manageServicesSuccessToast',
    DELETE_ORG: 'deleteOrgSuccessToast',
    ACTIVATE_SUBSCRIPTION: 'activateSubscriptionSuccessToast',
  },
};

export const STRIPE_TOASTS = {
  ERROR: {
    BUNDLE_UPDATE: 'bundleUpdateErrorToast',
    BUNDLE_CANCEL: 'bundleCancelErrorToast',
  },
  SUCCESS: {
    BUNDLE_UPDATE: 'bundleUpdateSuccessToast',
    BUNDLE_CANCEL: 'bundleCancelSuccessToast',
  },
};

export const ORGS_ADMIN_TOAST_ID = {
  ERROR: {
    GET_ALL: 'getAllOrgsErrorToast',
    UPDATE: 'updateOrgErrorToast',
  },
  SUCCESS: {
    UPDATE: 'updateOrgSuccessToast',
  },
};

export const SECURITY_MONITORING_TOAST_ID = {
  ERROR: {
    GET_ALL_ALERTS: 'getAllAlertInstancesErrorToast',
    GET_ALERT: 'getAlertInstanceErrorToast',
    ALERT_UPDATE: 'alertUpdateErrorToast',
    ASSIGN_ALERT: 'assignAlertErrorToast',
    GET_MONITORED_ADDRESSES: 'getMonitoredAddressesErrorToast',
    GET_WHITELISTED_ADDRESSES: 'getWhitelistedAddressesErrorToast',
    ADD_MONITORED_ADDRESS: 'addMonitoredAddressErrorToast',
    ADD_WHITELISTED_ADDRESS: 'addWhitelistedAddressErrorToast',
    DELETE_MONITORED_ADDRESS: 'deleteMonitoredAddressErrorToast',
    DELETE_WHITELISTED_ADDRESS: 'deleteWhitelistedAddressErrorToast',
    ADD_NOTE: 'addNoteErrorToast',
    EDIT_NOTE: 'editNoteErrorToast',
    DELETE_NOTE: 'deleteNoteErrorToast',
  },
  SUCCESS: {
    ADD_MONITORED_ADDRESS: 'addMonitoredAddressSuccessToast',
    ADD_WHITELISTED_ADDRESS: 'addWhitelistedAddressSuccessToast',
    DELETE_MONITORED_ADDRESS: 'deleteMonitoredAddressSuccessToast',
    DELETE_WHITELISTED_ADDRESS: 'deleteWhitelistedAddressSuccessToast',
    ALERT_UPDATE: 'alertUpdateSuccessToast',
    ASSIGN_ALERT: 'assignAlertSuccessToast',
    ADD_NOTE: 'addNoteSuccessToast',
    EDIT_NOTE: 'editNoteSuccessToast',
    DELETE_NOTE: 'deleteNoteSuccessToast',
  },
};
export const ENTITY_INTERACTION_TOAST_ID = {
  ERROR: 'entityInteractionErrorToast',
};

export const ADVANCED_SEARCH_TOAST_ID = {
  ERROR: 'advancedSearchErrorToast',
};
