import { evmToTronAddress, tronToEvmAddress } from './tron/convert';
import { z } from 'zod';

import { CHAINS } from 'blockscope/static/supportedChains.js';

export const evmAddressSchema = z
  .string()
  .trim()
  .toLowerCase()
  .refine((value) => /^0x[0-9a-f]{40}$/.test(value), {
    message:
      'Invalid EVM address. All EVM addresses are a hex string starting with 0x and 42 characters longs (including 0x).',
  });

const tronAddressSchema = z
  .string()
  .length(34, 'Tron address must be exactly 34 characters long.')
  .regex(
    /^T[1-9A-HJ-NP-Za-km-z]+$/,
    "Tron address must start with 'T' and be Base58-encoded."
  )
  .refine((address) => {
    try {
      tronToEvmAddress(address);
      return true;
    } catch (error) {
      return false;
    }
  }, 'Tron address must be a valid Base58-encoded string.');

const evmTransactionHashSchema = z
  .string({ required_error: 'string required' })
  .trim()
  .toLowerCase()
  .refine((value) => /^0x[0-9a-f]{64}$/.test(value), {
    message:
      'Invalid EVM transaction hash. All evm transaction hashes are a hex string starting with 0x and 66 characters longs (including 0x).',
  });

const genesisTxnSchema = z
  .string()
  .trim()
  .toLowerCase()
  .refine((value) => /^genesis_[0-9a-f]{40}$/.test(value), {
    message:
      'Invalid genesis transaction hash. All genesis transaction hashes are a hex string starting with genesis and 48 characters longs (including genesis_).',
  });

const isValidAddress = (address, chain) => {
  // regex automatically coerces the input to a string so we need to safeguard if the input is not a string
  if (typeof address !== 'string') {
    return false;
  }

  if (chain === CHAINS.BITCOIN) {
    return /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(address);
  }
  if (chain === CHAINS.TRON) {
    return (
      tronAddressSchema.safeParse(address).success ||
      evmAddressSchema.safeParse(address).success
    );
  }
  return evmAddressSchema.safeParse(address).success;
};

const isValidTransactionHash = (hash) => {
  return evmTransactionHashSchema.safeParse(hash).success;
};

const formatToEvmAddress = (address, fromChain) => {
  switch (fromChain) {
    case CHAINS.TRON:
      return tronAddressSchema.safeParse(address).success
        ? tronToEvmAddress(address).toLowerCase()
        : address.toLowerCase();
    default:
      return address.toLowerCase();
  }
};

const formatFromEvmAddress = (address, toChain) => {
  if (isValidAddress(address, CHAINS.ETHEREUM)) {
    switch (toChain) {
      case CHAINS.TRON:
        return !tronAddressSchema.safeParse(address).success
          ? evmToTronAddress(address)
          : address;
      default:
        return address.toLowerCase();
    }
  }
  return address;
};
const EVM_ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const addressLabelDefinitionSchema = z.object({
  _id: z.number().positive().optional(),
  label: z.string(),
  type: z.string(),
  priority: z.number().default(21),
  sourceCategory: z.string(),
  riskScore: z.union([z.number().min(0).max(100), z.null()]).optional(),
  riskWeight: z.number().min(1).max(10).default(5),
  riskOverride: z.boolean().default(false),
  logo: z.string().url().optional(),
});
/**
 * @typedef {z.infer<typeof addressLabelDefinitionSchema>} addressLabelDefinition
 */

const tokenDataSchema = z.object({
  address: z.union([evmAddressSchema, z.literal('native')]),
  decimals: z.number(),
  contractName: z.string(),
  symbol: z.string(),
  logo: z.string().url().optional(),
  chain: z.string(),
  priceSupported: z.boolean().default(false),
  prioritySearchable: z.boolean().default(false),
  isVerified: z.boolean().default(false),
  isScam: z.boolean().default(false),
});
/**
 * @typedef {z.infer<typeof tokenDataSchema>} tokenData
 */

const addressCreationDetailsSchema = z.object({
  creator: z.union([
    z.literal('genesis'),
    z.literal('GENESIS'),
    evmAddressSchema,
    z.object({
      _id: z.union([
        z.literal('genesis'),
        z.literal('GENESIS'),
        evmAddressSchema,
      ]),
      labels: z.array(addressLabelDefinitionSchema),
      token: tokenDataSchema.optional(),
      name: z.string().optional(),
      logo: z.string().url().optional(),
    }),
  ]),
  txHash: z.union([genesisTxnSchema, evmTransactionHashSchema]),
});
/** @typedef {z.infer<typeof addressCreationDetailsSchema>} addressCreationDetails */

const addressFundingDetailsSchema = z.object({
  funder: z.union([
    z.literal('genesis'),
    z.literal('GENESIS'),
    evmAddressSchema,
    z.object({
      _id: z.union([
        z.literal('genesis'),
        z.literal('GENESIS'),
        evmAddressSchema,
      ]),
      labels: z.array(addressLabelDefinitionSchema),
      token: tokenDataSchema.optional(),
      name: z.string().optional(),
      logo: z.string().url().optional(),
    }),
  ]),
  fundingTxn: z.union([genesisTxnSchema, evmTransactionHashSchema]).optional(),
  fundingDate: z.number(),
  blockNumber: z.number(),
});
/** @typedef {z.infer<typeof addressFundingDetailsSchema>} addressFundingDetails */

const enrichedAddressSchema = z.object({
  _id: z.union([z.literal('genesis'), z.literal('GENESIS'), evmAddressSchema]),
  labels: z.array(addressLabelDefinitionSchema),
  token: tokenDataSchema.optional(),
  name: z.string().optional(),
  logo: z.string().url().optional(),
  weightedRiskScore: z.number().min(0).max(100).optional(),
  maxRiskScore: z.number().min(0).max(100).optional(),
  creationDetails: addressCreationDetailsSchema.optional(),
  fundingDetails: addressFundingDetailsSchema.optional(),
  firstTransaction: z
    .object({
      hash: evmTransactionHashSchema,
      blockNumber: z.preprocess((v) => Number(v), z.number()),
      timestamp: z.preprocess((v) => Number(v), z.number()),
    })
    .optional(),
});
/**
 * @typedef {z.infer<typeof enrichedAddressSchema>} enrichedAddress
 */

const enrichedBalanceSchema = z.object({
  raw: z.union([z.string(), z.number()]),
  formatted: z.string(),
  displayText: z.string(),
  symbol: z.string().optional(),
  dollarValue: z.preprocess((v) => Number(v), z.number()).optional(),
});
/**
 * @typedef {z.infer<typeof enrichedBalanceSchema>} enrichedBalance
 */

export default {
  isValidAddress,
  evmAddressSchema,
  evmTransactionHashSchema,
  tronAddressSchema,
  addressLabelDefinitionSchema,
  tokenDataSchema,
  enrichedAddressSchema,
  enrichedBalanceSchema,
  isValidTransactionHash,
  formatToEvmAddress,
  formatFromEvmAddress,
  EVM_ZERO_ADDRESS,
};

