import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// images
import bg6 from 'blockscope/assets/landingBackgrounds/unsplash_bg6.jpg';
import decoder1 from 'blockscope/assets/newProductImages/decoder1.png';
import profiler2 from 'blockscope/assets/newProductImages/profiler2.png';
import tracer1 from 'blockscope/assets/newProductImages/tracer1.png';
import tracer2 from 'blockscope/assets/newProductImages/tracer2.png';

import Section from 'blockscope/components/common/Section';

const Hero = () => {
  const blockscopeScreenshots = {
    0: tracer1,
    1: tracer2,
    2: decoder1,
    3: profiler2,
  };

  return (
    <Section
      className='py-0 overflow-hidden light'
      image={bg6} //navy blue bg image
      position='center bottom'
      overlay
    >
      <Row className='justify-content-center align-items-center pt-10 pt-xxl-10'>
        <Col md={11} lg={8} xl={8} className='pb-8 text-center text-xl-start'>
          <h1 className='text-white fw-bold text-center'>
            {`Advanced Crypto Forensics Platform`}
            <br></br>
            <p className='lead text-light opacity-100 mt-3'>
              {`Research tools for Crypto professionals & investigators.`}
            </p>
          </h1>

          <hr className='my-4'></hr>

          <div className='text-center'>
            <Button
              as={Link}
              variant='success'
              size='lg'
              className='border-2 rounded-pill mt-4 fs-0 py-2 text-dark text'
              to='/signup'
            >
              Schedule Demo
              <FontAwesomeIcon
                icon='play'
                transform='shrink-6 down-1 right-5'
              />
            </Button>
          </div>
        </Col>

        <Col xl={{ span: 8, offset: 2 }} className='align-self-end '>
          <Link to='/' className='img-landing-banner'>
            <img
              className='img-fluid'
              src={
                blockscopeScreenshots[
                  Math.floor(
                    Math.random() * Object.keys(blockscopeScreenshots).length
                  )
                ]
              }
              alt=''
            />
          </Link>
        </Col>
        <Col></Col>
      </Row>
    </Section>
  );
};

export default Hero;

