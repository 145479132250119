import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import interfaces from 'blockscope-interfaces';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { z } from 'zod';

import { passwordResetSubmission } from 'stateManagement/redux/actions/authActions';

const ResetPasswordSchema = z
  .object({
    password: interfaces.dragon.user.passwordSchema,
    confirmPassword: interfaces.dragon.user.passwordSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

const PasswordResetForm_Bloc = ({ hasLabel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const resetToken = searchParams.get('resettoken');

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    passwordsMatch: false,

    password1: '',
    password2: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  // Handler
  const onSubmit = (formData) => {
    dispatch(passwordResetSubmission(formData.password, email, resetToken));
    navigate('/login');
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          name='password'
          type='password'
          {...register('password')}
          onChange={(e) => {
            const passwordText = e.target.value;
            setPasswordValidation({
              ...passwordValidation,
              length: passwordText.length >= 8 && passwordText.length <= 50,
              uppercase: passwordText.match(/[A-Z]/g),
              lowercase: passwordText.match(/[a-z]/g),
              number: passwordText.match(/\d/g),
              special: passwordText.match(/[^A-Za-z0-9]/g),
              passwordsMatch: passwordText === passwordValidation.password2,

              password1: passwordText,
            });
          }}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          name='confirmPassword'
          type='password'
          {...register('confirmPassword')}
          onChange={(e) => {
            const passwordText = e.target.value;

            setPasswordValidation({
              ...passwordValidation,
              passwordsMatch: passwordText === passwordValidation.password1,
              password2: passwordText,
            });
          }}
        />
      </Form.Group>

      <div style={{ textAlign: 'left' }}>
        <p
          className={`fs--1 mb-0 ${
            passwordValidation.length ? 'text-success' : 'text-danger'
          }`}
        >
          <FontAwesomeIcon
            icon={passwordValidation.length ? 'check' : 'xmark'}
            className='me-2'
          />
          8-50 characters
        </p>
        <p
          className={`fs--1 mb-0 ${
            passwordValidation.uppercase ? 'text-success' : 'text-danger'
          }`}
        >
          <FontAwesomeIcon
            icon={passwordValidation.uppercase ? 'check' : 'xmark'}
            className='me-2'
          />
          Atleast 1 uppercase letter
        </p>
        <p
          className={`fs--1 mb-0 ${
            passwordValidation.lowercase ? 'text-success' : 'text-danger'
          }`}
        >
          <FontAwesomeIcon
            icon={passwordValidation.lowercase ? 'check' : 'xmark'}
            className='me-2'
          />
          Atleast 1 lowercase letter
        </p>
        <p
          className={`fs--1 mb-0 ${
            passwordValidation.number ? 'text-success' : 'text-danger'
          }`}
        >
          <FontAwesomeIcon
            icon={passwordValidation.number ? 'check' : 'xmark'}
            className='me-2'
          />
          Atleast one number
        </p>
        <p
          className={`fs--1 mb-0 ${
            passwordValidation.special ? 'text-success' : 'text-danger'
          }`}
        >
          <FontAwesomeIcon
            icon={passwordValidation.special ? 'check' : 'xmark'}
            className='me-2'
          />
          Atleast one special character
        </p>
        <p
          className={`text-left fs--1 mb-2 ${
            passwordValidation.passwordsMatch ? 'text-success' : 'text-danger'
          }`}
        >
          <FontAwesomeIcon
            icon={passwordValidation.passwordsMatch ? 'check' : 'xmark'}
            className='me-2'
          />
          Passwords Match
        </p>
      </div>

      <Button type='submit' className='w-100'>
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm_Bloc.propTypes = {
  hasLabel: PropTypes.bool,
};

export default PasswordResetForm_Bloc;
