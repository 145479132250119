import { ORG_SERVICES } from 'blockscope/static/blockscopeServices';
import { OPEN_PAGE_POSTHOG } from 'blockscope/static/posthogEvents';
import { SOLUTIONS } from 'blockscope/static/solutionConsts';

export const Home_Routes = {
  label: 'Home',
  children: [
    {
      name: 'All Services',
      active: true,
      icon: 'cube',
      to: '/all-blockscope-services',
      posthogId: OPEN_PAGE_POSTHOG.ALL_SERVICES,
    },
    {
      name: 'My Dashboard',
      active: true,
      icon: 'table',
      to: '/dash',
      posthogId: OPEN_PAGE_POSTHOG.DASHBOARD,
    },
    {
      name: 'Admin Control',
      active: true,
      icon: 'desktop',
      to: '/admin-control',
      posthogId: OPEN_PAGE_POSTHOG.ADMIN_CONTROL,
      service: ORG_SERVICES.BLOCKSCOPE_INTERNAL_CONTROLS,
    },
  ],
  alwaysShowSection: true,
};

export const Explore_Chain_Routes = {
  label: 'Explore Chain',
  children: [
    {
      name: 'Block Breakdown',
      icon: 'cubes',
      to: '/block-breakdown',
      posthogId: OPEN_PAGE_POSTHOG.BLOCK_BREAKDOWN,
      active: true,
      service: ORG_SERVICES.BLOCK_BREAKDOWN,
    },
    {
      name: 'Transaction Decoder',
      icon: 'lock-open',
      to: '/transaction-decoder',
      posthogId: OPEN_PAGE_POSTHOG.TRANSACTION_DECODER,
      active: true,
      service: ORG_SERVICES.TRANSACTION_DECODER,
    },
    {
      name: 'Wallet Profile',
      icon: 'address-card',
      to: '/wallet-profile',
      posthogId: OPEN_PAGE_POSTHOG.WALLET_PROFILE,
      active: true,
      service: ORG_SERVICES.ADDRESS_ACTIVITY,
    },
    {
      name: 'Contract Usage',
      icon: 'chart-line',
      to: '/contract-usage',
      posthogId: OPEN_PAGE_POSTHOG.CONTRACT_USAGE,
      active: true,
      service: ORG_SERVICES.CONTRACT_USAGE,
    },
    {
      name: 'Contract Analysis',
      icon: 'puzzle-piece',
      to: '/contract-analysis',
      posthogId: OPEN_PAGE_POSTHOG.CONTRACT_BREAKDOWN,
      active: true,
      service: ORG_SERVICES.CONTRACT_BREAKDOWN,
    },
  ],
};

export const Monitoring_Routes = {
  label: 'Monitoring',
  children: [
    {
      name: 'Watchtower',
      icon: 'tower-cell',
      active: true,
      to: '/watchtower',
      posthogId: OPEN_PAGE_POSTHOG.WATCHTOWER.MAIN,
      service: ORG_SERVICES.WATCHTOWER,
    },
    {
      name: 'Security Monitoring',
      icon: 'shield-halved',
      active: true,
      to: '/security',
      posthogId: OPEN_PAGE_POSTHOG.SECURITY_MONITORING.DASHBOARD,
      service: ORG_SERVICES.ORACLE,
    },
  ],
};

export const Curated_Data_Routes = {
  label: 'Curated Data',
  children: [
    // {
    //   name: 'Web3 Projects',
    //   icon: 'diagram-project',
    //   to: '/web3-project-directory',
    //   posthogId: OPEN_PAGE_POSTHOG.WEB3_PROJECTS.DIRECTORY,
    //   active: true,
    //   service: ORG_SERVICES.WEB3_ENTITIES,
    // },
    {
      name: 'Address Labels',
      icon: 'tag',
      to: '/browse-labels',
      posthogId: OPEN_PAGE_POSTHOG.LABELS.ALL,
      active: true,
      service: ORG_SERVICES.WEB3_LABELS,
    },
  ],
};

export const My_Data_Routes = {
  label: 'Saved',
  children: [
    {
      name: 'My Data',
      icon: 'floppy-disk',
      active: true,
      to: '/memos',
      posthogId: OPEN_PAGE_POSTHOG.MEMOS,
      service: ORG_SERVICES.MEMO,
    },
  ],
};

export const More_Tools_Routes = {
  label: 'Dev Tools',
  children: [
    {
      name: 'EVM Tools',
      icon: 'shuffle',
      active: true,
      children: [
        {
          name: 'EVM Function Directory',
          icon: 'codepen',
          to: '/evm-function-directory',
          posthogId: OPEN_PAGE_POSTHOG.EVM_FUNCTIONS_DIRECTORY,
          active: true,
          service: ORG_SERVICES.EVM_FUNCTION_DIRECTORY,
        },
        {
          name: 'EVM Event Directory',
          icon: 'list',
          to: '/evm-event-directory',
          posthogId: OPEN_PAGE_POSTHOG.EVM_EVENTS_DIRECTORY,
          active: true,
          service: ORG_SERVICES.EVM_EVENT_DIRECTORY,
        },
      ],
    },
  ],
};

export const Forensics_Tools_Routes = {
  label: 'Forensics',
  children: [
    {
      name: 'Tracer',
      icon: 'shuffle',
      to: '/my-tracers',
      posthogId: OPEN_PAGE_POSTHOG.TRACER.ALL,
      active: true,
      service: ORG_SERVICES.TRACER_INVESTIGATION,
    },
    {
      name: 'Entity Interaction',
      icon: 'arrow-right-arrow-left',
      active: true,
      to: '/entity-interaction',
      service: ORG_SERVICES.ENTITY_INTERACTION,
      posthogId: OPEN_PAGE_POSTHOG.ENTITY_INTERACTION,
    },
    {
      name: 'Cohort Analysis',
      icon: 'hexagon-nodes',
      active: true,
      to: '/cohort-analysis',
      service: ORG_SERVICES.COHORT_ANALYSIS,
      posthogId: OPEN_PAGE_POSTHOG.COHORT_ANALYSIS,
    },
    {
      name: 'Advanced Search',
      icon: 'magnifying-glass',
      active: true,
      to: '/advanced-search',
      service: ORG_SERVICES.ENTITY_INTERACTION,
      posthogId: OPEN_PAGE_POSTHOG.ADVANCED_SEARCH,
    },
  ],
};

export const Research_Routes = {
  label: 'Research',
  children: [
    {
      name: 'Investigator',
      icon: 'user-secret',
      active: true,
      to: '/my-investigations',
      posthogId: OPEN_PAGE_POSTHOG.INVESTIGATOR.ALL,
      service: ORG_SERVICES.INVESTIGATOR,
    },
  ],
};

export const Pipeline_Routes = {
  label: 'Data Indexing',
  children: [
    {
      name: 'Pipelines',
      icon: 'stream',
      active: true,
      to: `/pipeline`,
      posthogId: OPEN_PAGE_POSTHOG.PIPELINE.ALL,
      service: ORG_SERVICES.PIPELINES,
    },
  ],
};

export const Developer_Docs_Routes = {
  label: 'Documentation',
  children: [
    {
      name: 'Api Docs',
      icon: 'terminal',
      active: true,
      children: [
        // {
        //   name: 'Decoder API',
        //   icon: 'lock-open',
        //   to: '/docs/api/v2/decoder/transaction',
        //   posthogId: OPEN_PAGE_POSTHOG.DECODER_API_DOCS,
        //   active: true,
        //   service: ORG_SERVICES.DECODER_API,
        // },
        {
          name: 'Labels API',
          icon: 'tag',
          to: '/docs/api/v2/labels/address',
          posthogId: OPEN_PAGE_POSTHOG.LABELS_API_DOCS,
          active: true,
          service: ORG_SERVICES.LABEL_API,
        },
        {
          name: 'Risk API',
          icon: 'triangle-exclamation',
          to: '/docs/api/v2/risk/address',
          posthogId: OPEN_PAGE_POSTHOG.RISK_API_DOCS,
          active: true,
          service: ORG_SERVICES.RISK_API,
        },
      ],
    },
  ],
};

export const blockscopeRoutes = {
  [SOLUTIONS.STUDIO]: [
    Home_Routes,
    Explore_Chain_Routes,
    Forensics_Tools_Routes,
    Monitoring_Routes,
    Research_Routes,
    Curated_Data_Routes,
    My_Data_Routes,
  ],
  [SOLUTIONS.DEVKIT]: [
    Home_Routes,
    Explore_Chain_Routes,
    Monitoring_Routes,
    Pipeline_Routes,
    Curated_Data_Routes,
    More_Tools_Routes,
    My_Data_Routes,
  ],
  [SOLUTIONS.DEFENDER]: [
    Home_Routes,
    Explore_Chain_Routes,
    Forensics_Tools_Routes,
    Monitoring_Routes,
    Research_Routes,
    Curated_Data_Routes,
    My_Data_Routes,
  ],
};

