import React from 'react';

import NavbarDropdownResearch from './dropdownOptions/NavbarDropdownResearch';

import NavbarDropdown from 'blockscope/components/navbar/top/NavbarDropdown';
import NavbarDropdownCommunity from 'blockscope/components/navbar/top/dropdownOptions/NavbarDropdownCommunity';

const NavbarTopDropDownMenus = () => {
  return (
    <>
      <NavbarDropdown title='Monitor'>
        <NavbarDropdownCommunity />
      </NavbarDropdown>

      <NavbarDropdown title='Research'>
        <NavbarDropdownResearch />
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;

