import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AppContext from 'context/Context';

const NavbarDropdownCommunity = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig,
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  const communityOptions = [
    {
      name: 'Public Watchtowers',
      to: '/community/watchtowers',
      isExternal: false,
    },
    {
      name: 'Stablecoin Activity',
      to: 'https://stablecoinmonitor.com/',
      isExternal: true,
    },
  ];

  return (
    <>
      {communityOptions.map((route) =>
        route.isExternal ? (
          <Dropdown.Item
            key={route.name}
            as='a'
            className={'link-600'}
            href={route.to}
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={'link-600'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        )
      )}
    </>
  );
};

NavbarDropdownCommunity.propTypes = {};

export default NavbarDropdownCommunity;

