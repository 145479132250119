import { ADVANCED_SEARCH_TYPES } from 'blockscope/components/advancedSearch/static/consts';
import { ORG_SERVICES } from 'blockscope/static/blockscopeServices.js';
import { OPEN_PAGE_POSTHOG } from 'blockscope/static/posthogEvents';

import { extractAddress } from 'blockscope/utils/helperUtils';

export const QUERY_TYPE_LABELS = {
  [ORG_SERVICES.TRANSACTION_DECODER]: `Transaction Decoder`,
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: `Transaction Simulator`,
  [ORG_SERVICES.BLOCK_BREAKDOWN]: `Block Breakdown`,
  [ORG_SERVICES.WATCHTOWER]: `Watchtower Document`,
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: `Contract Analysis`,
  [ORG_SERVICES.CONTRACT_USAGE]: `Contract Usage`,
  [ORG_SERVICES.ADDRESS_ACTIVITY]: `Wallet Profile`,
  [ORG_SERVICES.ENTITY_INTERACTION]: `Entity Interaction`,
  [ORG_SERVICES.TRACER_TOKENFLOW]: `Tracer Tokenflow`,
  [ORG_SERVICES.TRACER_TRANSACTION_TOKENFLOW]: `Tracer Transaction`,
  [ORG_SERVICES.COHORT_ANALYSIS]: `Cohort Analysis`,
  [ORG_SERVICES.ADVANCED_SEARCH]: `Advanced Search`,
};

export const QUERY_TYPE_LINKS = {
  [ORG_SERVICES.TRANSACTION_DECODER]: (params) => {
    return `/transaction-decoder/${
      params.chain
    }/${params.transactionHash?.toLowerCase()}`;
  },
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: (params) => {
    return `/transaction-simulator/${params.chain}?from=${
      params.from?._id || params.from
    }&to=${params.to?._id || params.to}&value=${params.value}&gas=${
      params.gas
    }&gasPrice=${params.gasPrice}&input=${params.input}&txnIndex=${
      params.txnIndex
    }&block=${params.block}`;
  },
  [ORG_SERVICES.BLOCK_BREAKDOWN]: (params) => {
    return `/block-breakdown/${params.chain}/${params.blockNumber}`;
  },
  [ORG_SERVICES.WATCHTOWER]: (params) => {
    return `/watchtower-details/${params.watchtower}`;
  },
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: (params) => {
    return `/contract-analysis/${params.chain}/${extractAddress(
      params.contractAddress
    )?.toLowerCase()}`;
  },
  [ORG_SERVICES.CONTRACT_USAGE]: (params) => {
    return `/contract-usage/${params.chain}/${extractAddress(
      params.contractAddress
    )?.toLowerCase()}/?startTime=${params.startTime}&endTime=${params.endTime}`;
  },
  [ORG_SERVICES.ADDRESS_ACTIVITY]: (params) => {
    return `/wallet-profile/${params.chain}/${extractAddress(
      params.address
    )?.toLowerCase()}`;
  },
  [ORG_SERVICES.ENTITY_INTERACTION]: (params) => {
    const tokensString =
      params.tokens?.length > 0
        ? `&tokens=${params.tokens.map((t) => extractAddress(t)).join(',')}`
        : '';
    return `/entity-interaction/${params.chain}/${
      params.queryType
    }/?entityA=${params.entityA
      .map((e) => e._id || e)
      .join(',')}&entityB=${params.entityB
      .map((e) => e._id || e)
      .join(',')}${tokensString}&startDate=${params.startDate}&endDate=${
      params.endDate
    }`;
  },
  [ORG_SERVICES.TRACER_TOKENFLOW]: (params) => {
    const { chain, startTime, endTime, filterAddresses, tokens } = params;
    const tokensString = tokens.map((t) => extractAddress(t)).join(',');
    const addressString = filterAddresses
      .map((addr) => extractAddress(addr))
      .join(',');
    return `/tracer?chain=${chain}&filterAddresses=${addressString}&tokens=${tokensString}&startDate=${startTime}&endDate=${endTime}`;
  },
  [ORG_SERVICES.TRACER_TRANSACTION_TOKENFLOW]: (params) => {
    const { chain, transactionHash } = params;
    return `/tracer?chain=${chain}&transactionHash=${transactionHash}`;
  },
  [ORG_SERVICES.COHORT_ANALYSIS]:
    /**
     * Creaates a Cohort Analysis Link
     * @param {Object} params
     * @param {String} params.chain
     * @param {String[]} params.addresses
     * @param {String} params.startDate
     * @param {String} params.endDate
     */
    (params) => {
      const { chain, addresses, startDate, endDate } = params;
      const cohortString = addresses
        .map((addr) => extractAddress(addr))
        .join(',');
      return `/cohort-analysis/${chain}/${cohortString}?startDate=${startDate}&endDate=${endDate}`;
    },
  [ORG_SERVICES.ADVANCED_SEARCH]: (params) => {
    const { chain, searchType, startTime, endTime } = params;
    const searchParams = {
      startTime,
      endTime,
    };

    if (searchType === ADVANCED_SEARCH_TYPES.CUSTOM) {
      const { sourceType, from, to } = params;
      if (from?.length > 0) {
        searchParams.from = from.map((addr) => extractAddress(addr)).join(',');
      }
      if (to?.length > 0) {
        searchParams.to = to.map((addr) => extractAddress(addr)).join(',');
      }
      searchParams.sourceType = sourceType;

      const searchString = new URLSearchParams(searchParams).toString();
      return `/advanced-search/${ADVANCED_SEARCH_TYPES.CUSTOM}/${chain}?${searchString}`;
    }
    if (searchType === ADVANCED_SEARCH_TYPES.ENTITY_INTERACTION) {
      const { queryType, entityA, entityB, tokens } = params;
      searchParams.entityA = entityA
        .map((addr) => extractAddress(addr))
        .join(',');
      searchParams.entityB = entityB
        .map((addr) => extractAddress(addr))
        .join(',');
      if (tokens?.length > 0) {
        searchParams.tokens = tokens
          .map((addr) => extractAddress(addr))
          .join(',');
      }
      searchParams.queryType = queryType;

      const searchString = new URLSearchParams(searchParams).toString();
      return `/advanced-search/${ADVANCED_SEARCH_TYPES.ENTITY_INTERACTION}/${chain}?${searchString}`;
    }
    if (searchType === ADVANCED_SEARCH_TYPES.CONTRACT_EVENTS) {
      const { contract, eventSignature } = params;

      if (eventSignature) {
        searchParams.eventSignature = eventSignature;
      }
      searchParams.contract = contract
        .map((addr) => extractAddress(addr))
        .join(',');

      const searchString = new URLSearchParams(searchParams).toString();
      return `/advanced-search/${ADVANCED_SEARCH_TYPES.CONTRACT_EVENTS}/${chain}?${searchString}`;
    }
    if (searchType === ADVANCED_SEARCH_TYPES.CONTRACT_FUNCTIONS) {
      const { contract, functionSignature } = params;

      if (functionSignature) {
        searchParams.functionSignature = functionSignature;
      }
      searchParams.contract = contract
        .map((addr) => extractAddress(addr))
        .join(',');

      const searchString = new URLSearchParams(searchParams).toString();
      return `/advanced-search/${ADVANCED_SEARCH_TYPES.CONTRACT_FUNCTIONS}/${chain}?${searchString}`;
    }
    if (searchType === ADVANCED_SEARCH_TYPES.ERC20) {
      const { tokens, account, direction } = params;
      searchParams.account = account
        .map((addr) => extractAddress(addr))
        .join(',');
      searchParams.direction = direction || 'both';
      if (tokens?.length > 0) {
        searchParams.tokens = tokens
          .map((addr) => extractAddress(addr))
          .join(',');
      }
      const searchString = new URLSearchParams(searchParams).toString();
      return `/advanced-search/${ADVANCED_SEARCH_TYPES.ERC20}/${chain}?${searchString}`;
    }
    if (searchType === ADVANCED_SEARCH_TYPES.ERC721) {
      const { tokens, account, direction } = params;
      searchParams.account = account
        .map((addr) => extractAddress(addr))
        .join(',');
      searchParams.direction = direction || 'both';
      if (tokens?.length > 0) {
        searchParams.tokens = tokens
          .map((addr) => extractAddress(addr))
          .join(',');
      }
      const searchString = new URLSearchParams(searchParams).toString();
      return `/advanced-search/${ADVANCED_SEARCH_TYPES.ERC721}/${chain}?${searchString}`;
    }
    return `/advanced-search`;
  },
};

export const QUERY_TYPE_ICONS = {
  [ORG_SERVICES.TRANSACTION_DECODER]: `lock-open`,
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: `terminal`,
  [ORG_SERVICES.BLOCK_BREAKDOWN]: `cubes`,
  [ORG_SERVICES.WATCHTOWER]: `desktop`,
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: `puzzle-piece`,
  [ORG_SERVICES.CONTRACT_USAGE]: `chart-line`,
  [ORG_SERVICES.ADDRESS_ACTIVITY]: `address-card`,
  [ORG_SERVICES.ENTITY_INTERACTION]: `arrow-right-arrow-left`,
  [ORG_SERVICES.TRACER_TOKENFLOW]: `shuffle`,
  [ORG_SERVICES.TRACER_TRANSACTION_TOKENFLOW]: `shuffle`,
  [ORG_SERVICES.COHORT_ANALYSIS]: `hexagon-nodes`,
  [ORG_SERVICES.ADVANCED_SEARCH]: `magnifying-glass`,
};

export const QUERY_TYPE_ICON_COLORS = {
  [ORG_SERVICES.TRANSACTION_DECODER]: `primary`,
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: `secondary`,
  [ORG_SERVICES.BLOCK_BREAKDOWN]: `info`,
  [ORG_SERVICES.WATCHTOWER]: `success`,
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: `success`,
  [ORG_SERVICES.CONTRACT_USAGE]: `warning`,
  [ORG_SERVICES.ADDRESS_ACTIVITY]: `danger`,
  [ORG_SERVICES.ENTITY_INTERACTION]: `info`,
  [ORG_SERVICES.TRACER_TOKENFLOW]: `warning`,
  [ORG_SERVICES.TRACER_TRANSACTION_TOKENFLOW]: `warning`,
  [ORG_SERVICES.COHORT_ANALYSIS]: `success`,
  [ORG_SERVICES.ADVANCED_SEARCH]: `warning`,
};

export const queryTypeToPostHog = {
  [ORG_SERVICES.TRANSACTION_DECODER]: OPEN_PAGE_POSTHOG.TRANSACTION_DECODER,
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: OPEN_PAGE_POSTHOG.TRANSACTION_SIMULATOR,
  [ORG_SERVICES.BLOCK_BREAKDOWN]: OPEN_PAGE_POSTHOG.BLOCK_BREAKDOWN,
  [ORG_SERVICES.WATCHTOWER]: OPEN_PAGE_POSTHOG.WATCHTOWER.DETAILS,
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: OPEN_PAGE_POSTHOG.CONTRACT_BREAKDOWN,
  [ORG_SERVICES.CONTRACT_USAGE]: OPEN_PAGE_POSTHOG.CONTRACT_USAGE,
  [ORG_SERVICES.ADDRESS_ACTIVITY]: OPEN_PAGE_POSTHOG.WALLET_PROFILE,
  [ORG_SERVICES.ENTITY_INTERACTION]: OPEN_PAGE_POSTHOG.ENTITY_INTERACTION,
  [ORG_SERVICES.TRACER_TOKENFLOW]: OPEN_PAGE_POSTHOG.TRACER_TOKENFLOW,
  [ORG_SERVICES.TRACER_TRANSACTION_TOKENFLOW]:
    OPEN_PAGE_POSTHOG.TRACER_TRANSACTION_TOKENFLOW,
  [ORG_SERVICES.COHORT_ANALYSIS]: OPEN_PAGE_POSTHOG.COHORT_ANALYSIS,
  [ORG_SERVICES.ADVANCED_SEARCH]: OPEN_PAGE_POSTHOG.ADVANCED_SEARCH,
};

