import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import cookieLogo from 'blockscope/assets/cookies.svg';

import CookieNotice from 'blockscope/components/common/CookieNotice';
import Flex from 'blockscope/components/common/Flex';

import Testimonial from '../components/Testimonial';
import Backers from 'blockscope/components/pages/landing/components/Backers';
import Chains from 'blockscope/components/pages/landing/components/Chains';
import Cta from 'blockscope/components/pages/landing/components/Cta';
import FooterStandard from 'blockscope/components/pages/landing/components/FooterStandard';
import Hero from 'blockscope/components/pages/landing/components/Hero';
import MailingListSection from 'blockscope/components/pages/landing/components/MalingListSection';
import NavbarStandard from 'blockscope/components/pages/landing/components/NavbarStandard';
import Tools from 'blockscope/components/pages/landing/components/Tools';
import WhatWeDo from 'blockscope/components/pages/landing/components/WhatWeDo';
import Processes from 'components/pages/landing/Processes';

const LandingPage = () => {
  window.scrollTo(0, 0);
  const [showCookieNotice, setShowCookieNotice] = useState(
    localStorage.getItem('BlockscopeCookiesAllowed') !== 'true'
  );

  return (
    <>
      <NavbarStandard />

      <Hero />
      <Tools />
      <Chains />

      <WhatWeDo />
      {/* <Timeline /> */}
      {/* <Processes /> */}
      {/* <Toolkits /> */}
      {/* <Testimonial /> */}
      {/* <Cta /> */}
      <Backers />
      {/* <MailingListSection /> */}

      <FooterStandard />
      <CookieNotice
        show={showCookieNotice}
        delay={3000}
        setShow={setShowCookieNotice}
      >
        <Card>
          <Card.Body>
            <Flex>
              <div className='pe-3'>
                <img src={cookieLogo} alt='cookie' width={40} />
              </div>
              <div className=''>
                <p>We use third party cookies to analyze site traffic.</p>
                <Button
                  size='sm'
                  variant='falcon-primary'
                  className='me-3'
                  onClick={() => {
                    setShowCookieNotice(!showCookieNotice);
                    localStorage.setItem('BlockscopeCookiesAllowed', true);
                  }}
                >
                  Okay
                </Button>
              </div>
            </Flex>
          </Card.Body>
        </Card>
      </CookieNotice>
    </>
  );
};

export default LandingPage;

