import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';

import AppContext from 'context/Context';

const NavbarDropdownResearch = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig,
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  const researchOptions = [
    {
      name: 'Investigations',
      to: 'https://research.blockscope.co/',
      exact: true,
      active: true,
    },
    {
      name: 'Blog',
      to: 'https://medium.com/@blockscope.co',
      exact: true,
      active: true,
    },
  ];

  return (
    <>
      {researchOptions.map((route) => (
        <Dropdown.Item
          key={route.name}
          as='a'
          className={route.active ? 'link-600' : 'text-500'}
          href={route.to}
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleDropdownItemClick}
        >
          {route.name}
        </Dropdown.Item>
      ))}
    </>
  );
};

NavbarDropdownResearch.propTypes = {};

export default NavbarDropdownResearch;

